<template lang="pug">
ScreenWidthProvider#startpage-categories.col-12.mb-5(v-slot="{ isWider, isNarrower }")
  .row.mx-0(
    v-if="isWider('md') && filteredCategories.length > 0"
  )
    .category-item.px-1(
      v-for="(category, i) in filteredCategories",
      :key="i"
      :class="`col-md-${12 / filteredCategories.length}`"
    )
      button.btn.btn-beige.btn-lg.btn-block.rounded-5(
        @click="() => navigateTo(category?.url)"
      ) {{ category?.name }}

  template(
    v-if="isNarrower('md')"
  )
    .d-flex.flex-wrap.justify-content-center
      .category-item.px-1(
        v-for="(category, i) in filteredCategories",
        :key="i"
        :class="`col-6 col-md-4 col-lg-3`"
      )
        button.btn.btn-beige.btn-lg.btn-block.rounded-5.mb-2(
            @click="() => navigateTo(category?.url)"
          ) {{ category?.name }}
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  data () {
    return {
      sizeListArticleImg: [{
        maxWidth: null,
        size: 625 * 3,
      }, {
        maxWidth: 920,
        size: 920 * 1.5,
      }],
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),

    ...mapState(useRootStore, {
      categories: 'startCategories',
      locale: 'locale',
    }),

    filteredCategories () {
      return (this.categories || [])
        .filter(category => Boolean(category) && (this.locale !== 'no' || !category.url.includes('/bussreiser')))
    },

    categoriesSlide () {
      return this.filteredCategories
        .map(category => ({
          image: category.picture_cloudinary,
          type: 'sized',
          title: category.name,
          link: {
            href: category?.url || '#',
          },
        }))
    },
  },
})
</script>
